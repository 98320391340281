<div style="margin: 20px">
  <div class="margin-left30 margin-right30">
    <div class="content-header flex-row">
      <h1 *ngIf="!userId">{{'New card' | translate}}</h1>
      <h1 *ngIf="userId">{{'Edit Card' | translate}}</h1>
      <div *ngIf="groupSelect === 'rooms'" class="margin-auto">
        <ng-container *ngIf="objectsFilterFull$ | async as selectedObjectGlobal">

        {{'Location' | translate}}
        <select
          class="margin-left20"
          (change)="onChangeObjectSubobjectRooms($event)"
          [ngModel]="selectedRoomDesignation"
        >
          <ng-container
            *ngFor="let object of (allObjects$ | async) | getObjects: selectedObjectGlobal[0]"
          >
            <option [value]="object.designation">
               {{ object.name }}
            </option>
            <option
              *ngFor="let subObject of object.projectSubobjects.$values"
              [value]="subObject.designation"
            >
             {{ subObject.name }}
            </option>
          </ng-container>
          <option
            [value]="'*/*/*/*'"
          >
            {{ "All" | translate }}
          </option>
        </select>
      </ng-container>
      </div>
      <div class="to-right">
        <ion-button *ngIf="groupSelect !== 'none'"  size="default" (click)="goBack()">{{'Back' | translate}}</ion-button>
        <ion-button *ngIf="groupSelect !== 'none'" class="margin-left5" size="default" (click)="confirm()">{{'Confirm' | translate}}</ion-button>
      </div>
    </div>
  </div>
  <ion-content
  [scrollEvents]="true"
  (ionScrollEnd)="onScrollEnd()"
  (click)="onScrollEnd()"
  class="main-content"
  >
  <div *ngIf="userReady" [hidden]="groupSelect !== 'none'" class="flex-row" style="margin-left: 40px; margin-top: 10px;">
    <!-- CARD FORM -->
    <div class="br-r-s min-width300 margin-bottom30"  >
      <div class="flex-row-space-between flex-row-center" style="align-items: center;">
        <h2 class="margin-left20 ">{{'Card' | translate}}</h2>
        <!-- <i class="icon-paste font-size28 margin-right10"></i> -->
        <!-- <ion-button
                  color="primary"
                  class="margin-right5 ion-no-border margin-top5"
                  fill="outline"
                  size="small"
                  (click)="pasteDates()"
                  >{{('Paste dates' | translate) + ' (F)'}}
                </ion-button> -->
      </div>
      <ion-list>
        <ion-item lines="none">
          <div class="content-form-group margin-top15">
            <label class="width-100px">{{'Arrival' | translate}}:</label>
            <div class="flex-row">
              <input
                valueChangedDelay="2000"
                (afterValueChanged)="timeFromChanged($event.target.value)"
                [ngModel]="selectedDateTime[0]  | date : 'd.M.y, HH:mm'"
                type="text"
                placeholder="{{'Date and time' | translate}}"
              />
              <i class="icon-calendar font-size32 margin-left5" (click)="openCalendarForDateAndTime($event)"></i>
            </div>
          </div>
          <div *ngIf="fromDateNotValid" class="red margin-left5">{{'Invalid' | translate}}</div>
        </ion-item>

        <ion-item lines="none">
          <div class="content-form-group">
            <label class="width-100px">{{'Departure' | translate}}:</label>
            <div class="flex-row">
              <input
                type="text"
                valueChangedDelay="2000"
                (afterValueChanged)="timeToChanged($event.target.value)"
                [ngModel]="selectedDateTime[1]  | date : 'd.M.y, HH:mm'"
                placeholder="{{'Date and time' | translate}}"/>
                <i class="icon-calendar font-size32 margin-left5" (click)="openCalendarForDateAndTime($event)"></i></div>
              </div>
              <div *ngIf="toDateNotValid" class="red margin-left5 margin-top10">{{'Invalid' | translate}}</div>
          </ion-item>

        <ion-item lines="none"
          ><div class="content-form-group">
            <label class="width-100px">{{'User' | translate}}:</label>
            <input [disabled]="userId" [(ngModel)]="userFullName" type="text" placeholder="{{'User' | translate}}"/>
          </div>
        </ion-item>

        <ion-item lines="none"
        ><div class="content-form-group">
          <label class="width-100px">{{'Email' | translate}} ({{'optional' | translate}}):</label>
          <input [(ngModel)]="userEmail" type="text" placeholder="Email"/>
        </div>
      </ion-item>

        <ion-item *ngIf="mainController | roomsCanBeJoined"
          ><div class="content-form-group">
            <label class="width-100px">{{'Room join' | translate}}:</label>
            <div *ngFor="let room of getJoinableRooms()" class="flex-row margin-top5">
              <ion-item no-lines>
                <ion-checkbox
                class="margin-right5"
                [checked]="joinRoomSelected(room)"
                (ionChange)="joinRoomCheckboxClicked($event, room.designation)">
              </ion-checkbox>
              {{ room.name }}
              </ion-item>
            </div>
          </div>
        </ion-item>
      </ion-list>
      <div class="margin-top30 margin-left20 margin-bottom30">
        <ion-button [disabled]="loading" class="margin-right5" size="default" (click)="onSave()">{{'Save' | translate}}</ion-button>
        <ion-button size="default" fill="outline" (click)="onCancel()">{{'Cancel' | translate}}</ion-button>
      </div>
    </div>
    <!-- KEY OPTIONS -->
    <div class="br-r-s margin-left20 margin-right20 min-width300 margin-bottom30" >
      <ng-container *ngIf="objectsFilterFull$ | async as selectedObjectGlobal">

      <div class="flex-row-center-full">
        <h2>{{'Key options' | translate}}</h2>
        <select
          class="margin-left20"
          (change)="onChangeObjectSubobjectAccess($event)"
          [ngModel]="selectedAccessDesignation"
        >
          <ng-container *ngFor="let object of (allObjects$ | async) | getObjects: selectedObjectGlobal[0]">
            <option [value]="object.designation">
               {{ object.name }}
            </option>
            <option
              *ngFor="let subObject of object.projectSubobjects.$values"
              [value]="subObject.designation"
            >
             {{ subObject.name }}
            </option>
          </ng-container>
          <option
            [value]="'*/*/*/*'"
          >
            {{ "All" | translate }}
          </option>
        </select>
      </div>
    </ng-container>




      <div class="rooms-data">
           <ng-container *ngIf="floorList$ | async as floorList">
             <ion-list>
             <ng-container
               *ngFor="
                 let floor of floorList
                   | getFloorsForControllerSelection
                     : accessControlListForLocationId
                     : selectedAccessDesignation
               "
             >
                      <ng-container *ngIf="floor | floorHaveAcLocationsPipe">
                        <ion-list-header class="margin-left20">
                          {{ floor.name || floor }}
                        </ion-list-header>
                        <div class="wrapper">
                              <ng-container
                                *ngFor="
                                  let accControl of accessControlListForLocationId
                                    | getAccessControllsOnFloor
                                    : floor
                                    : selectedAccessDesignation
                                "
                              >
                              <ion-item *ngIf="accControl.location.name !== location.name" class="margin-left20 min-width200" lines="none">
                                <ion-checkbox
                                  labelPlacement="end"
                                  [checked]="accControl.checked"
                                  tabindex="-1"
                                  class="margin-right5"
                                  justify="start"
                                  (click)="clickAccessControlCheckbox($event, accControl)"
                              > {{ accControl.location.name }}</ion-checkbox>


                                </ion-item>
                              </ng-container>
                              </div>
                      </ng-container>
                  </ng-container>
                </ion-list>
           </ng-container>
    </div>
    </div>
    <!-- ADDITIONAL ROOMS -->
    <div *ngIf="!(mainController | isAccessControl)" class="min-width300">
      <div class="flex-row-center-full">
        <h2 class="margin-right10">{{'Additional rooms' | translate}}</h2>
        <ion-button class="margin-left5" (click)="onClickNewRoom()">{{'New room' | translate}}</ion-button>
      </div>
      <ion-list class="wrapper">
        <ion-item *ngFor="let location of multipleRooms" class="min-width200">
          <ion-checkbox
            checked
            tabindex="-1"
            labelPlacement="end"
            justify="start"
            class="margin-right5"
            (click)="onClickMultipleRoomsCheckbox(location)"
          >{{location.name}}</ion-checkbox>
        </ion-item>
      </ion-list>
    </div>
  </div>

<dts-select-container
[hidden]="groupSelect !=='rooms'"
#selectContainerRooms="dts-select-container"
class="drag-area"
[(selectedItems)]="multipleRooms"
>
<div class="rooms-data">
   <ng-container *ngIf="locations">
      <ng-container *ngIf="floorList$ | async as floorList">
        <div
          *ngFor="
            let floor of floorList
              | getFloorsForRoomSelection
                : selectedRoomDesignation
                : locations
          "
          class="floor-box"
        >
          <div class="floor-number-box" title="{{floor.name}}">
            <span [ngClass]="{ 'vertical-text': floor.name }">
             <ng-container *ngIf="floor.name=== 'Undistributed'">{{'Undistributed' | translate}}</ng-container>
             <ng-container *ngIf="floor.name!== 'Undistributed'">{{ floor.name || floor | textSizeFilterPipe : 10 }}</ng-container>

          </span>
          </div>
          <div>
            <div class="flex-row">
               <div
                *ngFor="
                  let location of locations
                    | getRoomsOnFloorForSelection
                    : floor : location
                "
                [dtsSelectItem]="location"
                class="selectable-guest-room"
              >
                <p>{{ location.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
</div>
</dts-select-container>

</ion-content>
</div>
<app-modal-confirmation
    [type]="'cardholder'"
    [content]=""
    [cardType]="cardType"
    [loading]="modalLoading"
    [guestCard]="true"
    (decision)="onModalDecision($event)"
    *ngIf="openModal">
</app-modal-confirmation>

<app-modal-confirmation
    [type]="'cardExist'"
    [content]=""
    (decision)="onModalDecisionCardExist($event)"
    *ngIf="cardExist">
</app-modal-confirmation>
