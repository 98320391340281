import { HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';
import { ModalController, PopoverController, ToastController } from '@ionic/angular';
import { SelectContainerComponent } from 'ngx-drag-to-select/lib/select-container.component';
import { combineLatest, forkJoin, lastValueFrom, Observable, Subscription } from 'rxjs';
import { Card } from 'src/app/core/models/card/card.model';
import { Controller } from 'src/app/core/models/controller.model';
import { Permission } from 'src/app/core/models/permissions/permission.model';
import { User } from 'src/app/core/models/user/user.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiRolesService } from 'src/app/modules/roles/services/http/api-roles.service';
import { RolesService } from 'src/app/modules/roles/services/roles.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';
import { UsersService } from 'src/app/modules/users/services/users.service';
import { LocationGroup } from '../../../../shared/services/api-location-groups.service';
import { Setting } from 'src/app/core/models/project/setting.model';
import { LocationGroupsService } from 'src/app/shared/services/location-groups.service';
import { map, take, tap } from 'rxjs/operators';
import { GuestStayShortcut, SettingsService } from 'src/app/modules/settings/services/settings.service';
import { CardOnHolderService } from 'src/app/core/services/card-on-holder.service';
import { ApiProjectService, KeyOption } from 'src/app/modules/project/services/http/api-project.service';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { ControllerFilters } from 'src/app/core/models/view-filter.model';
import { DateTimeInlineComponent } from 'src/app/shared/components/date-time-inline/date-time-inline.component';
import { GuestStayDaysInputComponent } from '../guest-stay-days-input/guest-stay-days-input.component';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { Location } from 'src/app/core/models/project/location.model';
import { UserSettingsService } from 'src/app/shared/services/user-settings.service';
import { CustomTranslatePipe } from 'src/app/shared/pipes/custom-translate.pipe';
import moment from 'moment';
import { DEMO_MODE } from 'src/environments/environment';
import { DemoModeService } from 'src/app/shared/services/demo-mode.service';


@Component({
  selector: 'app-edit-card-modal',
  templateUrl: './edit-card-modal.component.html',
  styleUrls: ['./edit-card-modal.component.scss']
})
export class EditCardModalComponent implements OnInit, OnDestroy {

  @ViewChild('selectContainerRooms') selectContainerRooms: SelectContainerComponent;
  // @ViewChild('selectContainerAccess') selectContainerAccess: SelectContainerComponent;
  @ViewChild('dtFrom') dtFrom: OwlDateTimeComponent<any>;
  @ViewChild('dtTo') dtTo: OwlDateTimeComponent<any>;
  @Input() location: Location;
  @Input() userId: number;
  controllersInRoom: Controller[];
  editUser: User;
  originalEditUser: User;
  editUserCard: Card;
  cardOriginalState: Card;
  userReady = false;
  selectedDateTime: Date[] = [];
  multipleRooms: Location[] = []
  mainController: Controller;

  fromDateNotValid = false;
  toDateNotValid = false;
  fromToChanged = false;

  groupSelect: 'none' /* |'access' */ | 'rooms' = 'none';

  floorList$: Observable<LocationGroup[]> = this.locationGroupsService.getFloorList();

  permissions: Permission[];
  permissionsSubscription: Subscription;
  cardCreateShortcutSubscription: Subscription;

  loggedInUser = this.currentUserStoreService.getUser()
  cardOnHolder: boolean;
  cardOnHolderSubscription: Subscription;
  openModal = false;
  cardExist = false;
  modalLoading = false;
  userFullName: string;
  userEmail: string;
  userBeingCreated = false;
  roomsToJoin: string[] = [];
  allObjects$: Observable<ProjectObject[]> = this.projectService.getObjects();
  objectsFilterFull$ = this.filtersService.getObjectsFilterFull();
  selectedAccessDesignation = '*/*/*/*';
  selectedRoomDesignation = '*/*/*/*';
  accessControlListForLocationId: KeyOption[];
  loading = false;
  userGuestCreateShortcut$ = this.settingsService.getGuestStayShortcutForUser();
  locations: Location[] = this.projectService.getGuestRoomLocations();

  showFrom = false;
  daysStayOpen = false;
  localeId: string;
  checkInTimeAsLastFullHour: boolean;
  cardType: string;

  constructor(private modalController: ModalController,
              private locationGroupsService: LocationGroupsService,
              private apiUsersService: ApiUsersService,
              private cardsService: CardsService,
              private projectService: ProjectService,
              private rolesService: RolesService,
              private apiRolesService: ApiRolesService,
              private usersService: UsersService,
              private settingsService: SettingsService,
              private cardOnHolderService: CardOnHolderService,
              private apiProjectService: ApiProjectService,
              private filtersService: FiltersService,
              private popoverController: PopoverController,
              private toastController: ToastController,
              private currentUserStoreService: CurrentUserStoreService,
              private userSettingsService: UserSettingsService,
              private pipe: CustomTranslatePipe,
              private demoModeService: DemoModeService
             ) { }

  ngOnInit(): void {
    this.localeId = this.userSettingsService.getLanguage();
    this.getCheckInAsLastFullHour();
    this.controllersInRoom = this.location.controllers.$values
    this.mainController = Controller.getMainController(this.controllersInRoom)
    this.cardType = Controller.getCardType(this.mainController);
    if (DEMO_MODE) {
      this.demoModeService.getData().subscribe( value => {
        this.accessControlListForLocationId = value.KeyOption
        if (this.userId) {
          this.editUser = value.User
          if (this.editUser.cards.length > 0) {
            this.editUserCard = this.editUser.cards[0];
          } else {
            this.editUserCard = this.editUser.cardLaters[0];
          }
        }
        this.userReady = true;
      })
    }
    this.apiProjectService.getKeyOptionsAccessControllListForLocationId(this.mainController.locationId).subscribe( keyOptions => {
      this.accessControlListForLocationId = keyOptions;
      if (this.userId) { // EDIT CARD
        this.apiUsersService.getUser(this.userId).subscribe( user => {
          this.editUser = user;
          if (this.editUser.cards.length > 0) {
            this.editUserCard = this.editUser.cards[0];
          } else {
            this.editUserCard = this.editUser.cardLaters[0];
          }
          this.cardOriginalState = structuredClone(this.editUserCard)
          this.originalEditUser = structuredClone(this.editUser);
          this.selectedDateTime[0] = new Date(this.editUserCard.validFrom);
          this.selectedDateTime[1] = new Date(this.editUserCard.validTo);
          this.userFullName = user.fullName;
          this.userEmail = user.email

          const group = JSON.parse(this.editUserCard.groups)
          if (group.joinedRooms.length > 0) {
            group.joinedRooms.forEach( element => {
              const locationId = element + '/0';
              if (locationId !== this.mainController.designation) {
                this.roomsToJoin.push(locationId)
              }
            });
          }
          this.addCardPermissionsOnCardEdit(user);
          this.userReady = true;
        })
      } else { // NEW CARD
        combineLatest([
          this.settingsService.getDefaultCheckinTime(),
          this.settingsService.getDefaultStayDuration(),
          this.settingsService.getDefaultCheckoutTime()
          ])
          .pipe(take(1))
          .subscribe(([defaultCheckinTime, defaultStayDurationDays, defaultCheckoutTime])=> {
            const arrival = new Date();
            arrival.setHours(defaultCheckinTime.hour);
            arrival.setMinutes(defaultCheckinTime.minute);
            if(this.checkInTimeAsLastFullHour){
              const currentDate = new Date();
              const currentHour = currentDate.getHours();
              currentDate.setHours(currentHour);
              currentDate.setMinutes(0);
              this.selectedDateTime[0] = currentDate;
            }else{
              this.selectedDateTime[0] = arrival;
            }
            const departure = new Date();
            departure.setHours(defaultCheckoutTime.hour);
            departure.setMinutes(defaultCheckoutTime.minute);
            departure.setDate(departure.getDate() + defaultStayDurationDays);
            this.selectedDateTime[1] = departure;

            // check if we will use how long guess stay modal
            this.cardCreateShortcutSubscription = this.userGuestCreateShortcut$.subscribe( value => {
              if (value) {
              const user = value.find( (data: GuestStayShortcut) => data.userId == this.loggedInUser.userId)
              if (user.active) {
                this.openStayDaysModal()
              }
              }
            })
            this.userReady = true;
          })
      }
      this.getPermissions();
      this.getCardOnHolderStatus();
    });
  }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
      if (event.key === 'Enter' && this.daysStayOpen == false) {
        this.onSave()
      }
      // if(event.key === 'f'){
      //   this.pasteDates();
      // }
    }


  checkIfcardExists() {
    return this.cardOnHolderService.cardByUidExists(this.cardsService.lastCardsOnHolderUid);
  }

  getCardOnHolderStatus() {
    this.cardOnHolder = this.cardOnHolderService.getCardOnHolder();
    this.cardOnHolderSubscription = this.cardOnHolderService.cardOnHolderChanged.subscribe(cardOnHolder => {
      this.cardOnHolderChanged(cardOnHolder)
    });
  }

  getCheckInAsLastFullHour(){
    const value = this.settingsService.getCheckInAsLastFullHour();
    if(value === 'true'){
      this.checkInTimeAsLastFullHour = true;
    }else{
      this.checkInTimeAsLastFullHour = false;
    }
  }

  ionViewDidEnter	() {
      this.selectContainerRooms.update();// "select container" needs to be updated on each change of its size and position
      // this.selectContainerAccess.update();// "select container" needs to be updated on each change of its size and position
  }


  async openStayDaysModal() {
    this.daysStayOpen = true;
    const popover = await this.popoverController.create({
      component: GuestStayDaysInputComponent,
      cssClass: 'stay-days',
      showBackdrop: false
    });
    await popover.present();

    popover.onDidDismiss().then( (data) => {
      this.daysStayOpen = false;
      if (data.data) {
        this.settingsService.getDefaultCheckoutTime().subscribe( value => {
          const days = +data.data
          const departure = new Date();
          departure.setHours(value.hour);
          departure.setMinutes(value.minute);
          departure.setDate(moment(this.selectedDateTime[0]).toDate().getDate() + days);

          this.selectedDateTime[1] = departure;
        })
      }
    })
  }

  onScrollEnd() {
    this.selectContainerRooms.update();
    // this.selectContainerAccess.update();
  }

  getPermissions() {
    this.permissions = this.rolesService.getPermissions();
    this.apiRolesService.getPermissions().subscribe();
    this.permissionsSubscription = this.rolesService.permissionsChanged.subscribe(() => {
      this.permissions = this.rolesService.getPermissions();
    });
  }

  onClickNewRoom() {
    this.groupSelect = 'rooms';
    const multipleRoomsIds = [];
    this.multipleRooms.forEach( loc => {
        multipleRoomsIds.push(loc.locationId.toString());
    })
    this.selectContainerRooms.clearSelection();
    this.selectContainerRooms.selectItems<Location>(item => {
      if (multipleRoomsIds.includes(item.locationId.toString())) {
        return true;
      }
    })
    this.selectContainerRooms.update();
  }

 checkIfRoomsHaveFreeSpace() {
  this.loading = true;
  let requestsToDo: Observable<any>[] = [];
    this.multipleRooms.forEach ( (location:Location) => {
      requestsToDo.push(this.apiUsersService.getCardsByLocationAndType(location.locationId, 1).pipe(map( (gCards: Card[]) => {
        gCards = gCards.filter( card => !card.isBackup);
        return [(Controller.getMaxGuestCardsNumber(Controller.getMainController(location.controllers.$values)) - 1) - gCards.length, location.name]
      })))
    })
    forkJoin([...requestsToDo]).subscribe( (result: Array<any[]>) => {
      let roomsWithoutSpace = [];
      result.forEach( value => {
        if (value[0] == 0) {
          roomsWithoutSpace.push(value[1])
          this.multipleRooms = this.multipleRooms.filter( room => room.name != value[1])
        }
      })

      if (roomsWithoutSpace.length >= 1) {
        this.toastNoRoomSpace();
      }
      this.loading = false;
    })
  }

  async toastNoRoomSpace() {
    let msg: string;
      if (this.localeId === 'en') {
        msg = "Some rooms were unavailable and were removed from select duo to insufficient guest card space."
      } else {
        "Neke sobe su nedostupne i maknute su iz odabira zbog nedostatka mjesta za kartice gostiju."
      }

    const toast = await this.toastController.create({
      message: `${msg}` ,
      color: 'danger',
      duration: 4000,
      buttons: [ {
        text: 'Dismiss',
        role: 'cancel'
      }],
    });
    await toast.present();
  }


  addCardPermissionsOnCardEdit(user: User) {
    this.accessControlListForLocationId.forEach((kO)=> {
      kO.checked = false;
    })

    user.permissions.forEach( (perm: Permission) => {
      const locationId = perm.locationId
      if((locationId && perm.typeId === 1) && !(this.mainController.locationId === locationId)) {
        // check if perm is access control
        const keyOption = this.accessControlListForLocationId.find((kOption)=> {
          return kOption.location.locationId === locationId;
        })
        if (keyOption) {
          keyOption.checked = true;
          return;
        }
        // check if perm is guest room perm
        const guestRoomCont = this.locations.find((location: Location)=> {
          return location.locationId === locationId;
        })
        if (guestRoomCont && !this.roomsToJoin.includes(locationId.toString()) && this.mainController.locationId !== locationId) {
          this.multipleRooms.push(guestRoomCont);
        }
      }
    })
  }

  onClickMultipleRoomsCheckbox(controller: Controller) {
    this.multipleRooms = this.multipleRooms.filter( element => element.locationId !== controller.locationId)
  }

  timeFromChanged(event) {
    this.fromToChanged = true;
    let timeValues = event.split(' ');
    let fullDate = new Date()
    if (!Number.isNaN(+timeValues[0])) {
      if(timeValues[0]) {
        fullDate.setDate(timeValues[0])
      }
      if(timeValues[1]) {
        fullDate.setMonth(timeValues[1]-1)
      }
      if(timeValues[2]) {
        fullDate.setFullYear(timeValues[2])
      }
      if(timeValues[3]) {
        fullDate.setHours(timeValues[3])
        fullDate.setMinutes(0)
      }
      if(timeValues[4]) {
        fullDate.setMinutes(timeValues[4])
      }
      this.selectedDateTime[0] = fullDate;
    } else {
      const date = timeValues[0].replace(',',"").split('.')
      let hours
      if (timeValues[1]) {
        hours = timeValues[1].split(':')
      }
      if (date[0]) {
        fullDate.setDate(date[0])
      }
      if (date[1]) {
        fullDate.setMonth(date[1]-1)
      }
      if (date[2]) {
        fullDate.setFullYear(date[2])
      }
      if(hours && hours[0]) {
        fullDate.setHours(hours[0])
        fullDate.setMinutes(0)
      }
      if(hours && hours[1]) {
        fullDate.setMinutes(hours[1])
      }
      if(fullDate instanceof Date && !isNaN(fullDate.valueOf())) {
        this.selectedDateTime[0] = fullDate;
      } else {
        this.selectedDateTime[0] = new Date()
      }
    }
  }

  timeToChanged(event) {
    let timeValues = event.split(' ');
    let fullDate = new Date()
    if (!Number.isNaN(+timeValues[0])) {
      if(timeValues[0]) {
        fullDate.setDate(timeValues[0])
      }
      if(timeValues[1]) {
        fullDate.setMonth(timeValues[1]-1)
      }
      if(timeValues[2]) {
        fullDate.setFullYear(timeValues[2])
      }
      if(timeValues[3]) {
        fullDate.setHours(timeValues[3])
        fullDate.setMinutes(0)
      }
      if(timeValues[4]) {
        fullDate.setMinutes(timeValues[4])
      }
      this.selectedDateTime[1] = fullDate;
    } else {
      const date = timeValues[0].replace(',',"").split('.')
      let hours
      if (timeValues[1]) {
        hours = timeValues[1].split(':')
      }
      if (date[0]) {
        fullDate.setDate(date[0])
      }
      if (date[1]) {
        fullDate.setMonth(date[1]-1)
      }
      if (date[2]) {
        fullDate.setFullYear(date[2])
      }
      if(hours && hours[0]) {
        fullDate.setHours(hours[0])
        fullDate.setMinutes(0)
      }
      if(hours && hours[1]) {
        fullDate.setMinutes(hours[1])
      }
      if(fullDate instanceof Date && !isNaN(fullDate.valueOf())) {
        this.selectedDateTime[1] = fullDate;
      } else {
        this.selectedDateTime[1] = new Date()
      }

    }
  }

  async openCalendarForDateAndTime(ev) {
    this.fromToChanged = true;
    const popover = await this.popoverController.create({
      component: DateTimeInlineComponent,
      componentProps:  {selectedDateTime : this.selectedDateTime},
      cssClass: 'date-time-inline-popover',
      event: ev,
      showBackdrop: false
    });
    await popover.present();

    popover.onDidDismiss().then( (data) => {
    })
  }


  onCancel() {
    this.modalController.dismiss();
  }



  onModalDecision(decision) {
    this.openModal = false;
 }

  onModalDecisionCardExist(decision) {
    this.cardExist = false;
 }

  goBack() {
    this.selectContainerRooms.clearSelection();
    this.selectContainerRooms.update()
    this.groupSelect = 'none';
  }

  confirm() {
    this.groupSelect = 'none';
    this.checkIfRoomsHaveFreeSpace();
  }

  cardOnHolderChanged(cardOnHolder: boolean) {
    this.cardOnHolder = cardOnHolder;
    if (this.openModal === true && this.cardOnHolder === true) {
      this.onSave();
    }
  }

  checkRequiredFields() {
    this.fromDateNotValid = false;
    this.toDateNotValid = false;
    if (!this.selectedDateTime[0]) {
      this.fromDateNotValid = true;
    }
    if (!this.selectedDateTime[1] || this.selectedDateTime[1] < new Date()) {
      this.toDateNotValid = true;
    }
  }



  onSave() {
      this.checkRequiredFields();
      if (this.fromDateNotValid || this.toDateNotValid) {
       return;
      }

    if (this.userId) { // EDIT CARD
        const multipleRoomsIds: string[] = [];
        const accessControlIds: string[] = []
        this.multipleRooms.forEach( loc => {
          multipleRoomsIds.push(loc.locationId.toString());
        })

        this.accessControlListForLocationId.forEach( (keyOption: KeyOption) => {
          if (keyOption.checked) {
            accessControlIds.push(keyOption.location.locationId.toString());
          }
        })
          this.editUser.permissions = [];

          this.permissions.forEach( perm => {
            if(perm.locationId && (perm.typeId === 1) && (this.location.locationId === perm.locationId ||
               multipleRoomsIds.includes(perm.locationId.toString()) || accessControlIds.includes(perm.locationId.toString()))) {
               this.editUser.permissions.push(perm);
            }
          })

            this.editUserCard.validFrom = this.selectedDateTime[0];
            this.editUserCard.validTo = this.selectedDateTime[1];

            if (this.roomsToJoin.length > 0) {
              const joinRoomArray = [];

              this.roomsToJoin.forEach( room => {
                joinRoomArray.push(room.substring(0, room.length - 2))
              })

              joinRoomArray.push(this.mainController.designation.substring(0, this.mainController.designation.length-2))
              this.editUserCard.groups = '{"joinedRooms": [';
              joinRoomArray.forEach( (location, index) => {
                this.editUserCard.groups = this.editUserCard.groups + '"' + location + '"';
                if (index !== joinRoomArray.length -1) {
                  this.editUserCard.groups = this.editUserCard.groups + ',';
                }
              })
              this.editUserCard.groups = this.editUserCard.groups + ']}'
            } else {
              this.editUserCard.groups = null;
            }

            // need to do this for card update
            this.editUser.cards = this.originalEditUser.cards;
            this.editUser.cardLaters = this.originalEditUser.cardLaters;
            this.editUser.email = this.userEmail === ''? null : this.userEmail;
            this.loading = true;
            this.apiUsersService.updateUser(this.editUser).subscribe({
              next: (createdUser) => {
                if(this.fromToChanged){
                  this.updateCard();
                }else{
                  this.modalController.dismiss();
                  this.usersService.guestAdded();
                  this.loading = false;
                }
              },
              error: (err)=> {
                this.loading = false;
              }}
              );
        // }
    } else { // NEW CARD
      if (this.cardOnHolder === true) {
        this.checkIfcardExists().subscribe( (cardExists) => {
          if (cardExists === false) {
            const multipleRoomsIds = [];
            const accessControlIds = [];
            this.multipleRooms.forEach( loc => {
              multipleRoomsIds.push(loc.locationId.toString());
            })
            this.accessControlListForLocationId.forEach( (keyOption: KeyOption) => {
              if (keyOption.checked) {
                accessControlIds.push(keyOption.location.locationId.toString());
              }
            })
            const user: User = new User();
            user.typeId = 3;
            if (this.userFullName === undefined || this.userFullName === '') {
              user.firstName = 'Guest'
              user.lastName = "";
              user.lastName = this.getRandomId();
            } else {
              const nameSplit = this.userFullName.split(' ')
              user.firstName = nameSplit[0]
              user.lastName = nameSplit[1]
              if (user.lastName === undefined || user.lastName === '') {
                user.lastName = this.getRandomId();
              }
            }
            user.email = this.userEmail;
            user.permissions = [];

            this.permissions.forEach( perm => {
              if(perm.locationId && (perm.typeId === 1) && (this.location.locationId === perm.locationId ||
                multipleRoomsIds.includes(perm.locationId.toString()) || accessControlIds.includes(perm.locationId.toString()))) {
                user.permissions.push(perm);
              }
            })
            user.cards = [];
            const card: Card = new Card();

            if (this.roomsToJoin.length > 0) {
              const joinRoomArray = [];
              this.roomsToJoin.forEach( room => {
                joinRoomArray.push(room.substring(0, room.length - 2))
              });
              joinRoomArray.push(this.mainController.designation.substring(0, this.mainController.designation.length-2))
              card.groups = '{"joinedRooms": [';
              joinRoomArray.forEach( (location, index) => {
                card.groups = card.groups + '"' + location + '"';
                if (index !== joinRoomArray.length -1) {
                  card.groups = card.groups + ',';
                }
              });
              card.groups = card.groups + ']}'
            }

            card.type = 1;
            if (this.isAccessControll(this.mainController)) {
              user.firstName = 'AC'
              user.lastName = this.getRandomId();
            }
            card.uid = this.cardsService.lastCardsOnHolderUid;
            card.userId = 0;
            card.validFrom = this.selectedDateTime[0];
            card.validTo = this.selectedDateTime[1];
            card.blacklisted = 0;
            user.cards.push(card);
            //saving latest card dates
            this.projectService.setLatestCardFrom(moment(this.selectedDateTime[0]).toDate());
            this.projectService.setLatestCardTo(moment(this.selectedDateTime[1]).toDate());
            this.modalController.dismiss();
            if (!this.userBeingCreated) {
              this.userBeingCreated = true;
              this.loading = true;
              this.modalLoading = true;
              this.openModal = true;
              this.apiUsersService.addUser(user).subscribe({
                next:(createdUser) => {
                  this.userBeingCreated = false;
                  this.modalLoading = false;
                  this.usersService.guestAdded();
                },
                error: ()=> {
                  this.loading = false;
                  this.modalLoading = false;
                }
              }
              );
              }
            // }
          }else {
            this.cardExist = true;
          }
        })
      } else {
        this.openModal = true;
      }
    }
  }

  updateCard() {
    if ( new Date (this.editUserCard.validFrom) <= new Date()) {
      if (new Date (this.cardOriginalState.validFrom) <= new Date()) {
            this.apiUsersService.updateUserCard(this.editUserCard).subscribe({
              next: () => {
                this.modalController.dismiss();
                this.usersService.guestAdded();
              },
              error: ()=> {
                this.loading = false;
              }
            });
      } else {
        this.apiUsersService.activateUserCard(this.editUserCard).subscribe({
          next: (updatedCard: Card) => {
          this.modalController.dismiss();
          this.usersService.guestAdded();
          },
          error: ()=> {
            this.loading = false;
          }
      });
      }
    } else if (new Date (this.editUserCard.validFrom) > new Date()) {
      if (new Date (this.cardOriginalState.validFrom) > new Date()) {
        this.apiUsersService.updateUserCardLater(this.editUserCard).subscribe(
          {next: (updatedCard: Card) => {
            this.modalController.dismiss();
            this.usersService.guestAdded();
          },
          error: ()=> {
            this.loading = false;
          }
        });
      } else {
        this.apiUsersService.deactivateUserCard(this.editUserCard).subscribe({
          next: (updatedCard: Card) => {
            this.modalController.dismiss();
            this.usersService.guestAdded();
          },
          error: ()=> {
            this.loading = false;
          }
        });
      }
    }
  }

  getRandomId() {
    const randomInt = this.getRandomInt(1,9999999)
    return String(randomInt).padStart(7, '0'); // '0009'
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

  onChangeObjectSubobjectAccess(event) {
    this.selectedAccessDesignation = event.target.value;


    // reset key options
    this.accessControlListForLocationId.forEach((kO)=> {
      kO.checked = false;
    })
     }



  onChangeObjectSubobjectRooms(event) {
    this.selectedRoomDesignation = event.target.value;

    this.selectContainerRooms.clearSelection();
    this.selectContainerRooms.update();
  }

  clickAccessControlCheckbox(event, accControl: KeyOption) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.cancelBubble = true;
    event.stopPropagation();
    if (event.target.checked) {
      accControl.checked = false;
    } else if (!event.target.checked) {
      accControl.checked = true;

    }
  }



  getJoinableRooms() {
    //TODO JOIN ROOM KARTICE
    const joinSetting = this.mainController.controllerSettings.$values.find( (setting: Setting) => {
      if (setting.name.toLowerCase() === 'joinedrooms' ) {
        return true;
      }
    })
    const controllers = [];
    const locationIds = joinSetting.value.split(',')
    locationIds.forEach ( (loc, index) => {
      loc = loc + '/0';
      if (this.mainController.designation !== loc) {
        controllers.push(this.projectService.getControllerByDesignation(loc));
      }
    })
    return controllers;
  }

  joinRoomCheckboxClicked(event: any, location: string) {
    if (event.target.checked) {
      this.roomsToJoin.push(location);
    } else {
      this.roomsToJoin = this.roomsToJoin.filter( element => element !== location)
    }
  }

  joinRoomSelected(controller: Controller) {
    return this.roomsToJoin.includes(controller.designation);
  }

  isAccessControll(controller: Controller) {
    return Controller.isAccessControl(controller);
  }

  // async pasteDates(){
  //   try{
  //     navigator.clipboard.readText().then(async dates => {
  //       if(dates && dates.trim() !== ''){
  //         const dateParts = dates.split(" - ");
  //         const validFrom = new Date(dateParts[0]);
  //         const validTo =new Date(dateParts[1]);
  //         if (!isNaN(validFrom.getTime()) && !isNaN(validTo.getTime())) {
  //           this.selectedDateTime[0] = validFrom;
  //           this.selectedDateTime[1] = validTo;
  //         }
  //       }
  //       const toast = await this.toastController.create({
  //         message: this.pipe.transform('Dates pasted'),
  //         duration:2000
  //       });
  //       await toast.present();
  //     });
  //   } catch (error){
  //     console.log('failed to read clipboard:', error);
  //   }
  // }

  ngOnDestroy() {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
    if (this.cardOnHolderSubscription) {
      this.cardOnHolderSubscription.unsubscribe();
    }
    if (this.cardCreateShortcutSubscription) {
      this.cardCreateShortcutSubscription.unsubscribe()
    }
  }

}
