import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';
import { API_BASE_URL } from 'src/environments/environment';
import { LocationGroupsService } from './location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';
// import { RoomsOverviewService } from './rooms-overview.service';

export class LocationGroup {
  id?:number;
  locationGroupTypeId: number;
  name: string;
  locations: Location[];
}

@Injectable({
  providedIn: 'root'
})
export class ApiLocationGroupsService {

  constructor(
    private http: HttpClient,
    private locationGroupsService: LocationGroupsService,
    // private roomOverviewService: RoomsOverviewService
    ) {}

  getLocationGroups() {
    return this.http.get<LocationGroup[]>(API_BASE_URL + '/locations/groups')
    .pipe(
      tap((locGroups: LocationGroup[]) => {
          this.locationGroupsService.setLocationGroups(locGroups);
      })
    );
  }


  updateLocationGroup(updatedLocationGroup: LocationGroup) {
    return this.http.put(API_BASE_URL + '/locations/groups', updatedLocationGroup)
  }

  createNewLocationGroup(newLocationGroup: LocationGroup) {
    return this.http.post(API_BASE_URL + '/locations/groups', newLocationGroup)
  }

  deleteLocationGroup(locationGroupId: number) {
    return this.http.delete(API_BASE_URL + `/locations/groups/${locationGroupId}`);
  }

  getLocationGroupsByType(locationGroupType: LocationGroupType) {
    return this.http.get<LocationGroup[]>(API_BASE_URL + `/locations/groups/byType?groupType=${locationGroupType}`)
    .pipe(
      tap((locGroup)=> {
        if(locationGroupType === LocationGroupType.FloorGroup) {
          this.locationGroupsService.setFloorList(locGroup);
          // this.roomOverviewService.initializeFloorFilter();
        }
    }));
  }
}
