import { Setting } from './project/setting.model';
import { Property } from './project/property.model';
import { ControllerCommisioningParams } from './project/controller-commissioning-params';


export interface ControllerProperties {
  $type: string;
  $values: Property[];
}

export interface ControllerSettings {
  $type: string;
  $values: Setting[];
}

export interface CardPositions {
  $type: string;
  $values: any[];
}

export interface LocationLocationGroups {
  $type: string;
  $values: any[];
}

export interface Permissions {
  $type: string;
  $values: any[];
}

export interface ControllerTags {
  $type: string;
  $values: any[];
}

// export interface ControllerInLocation {
//   $type: string,
//   $values: Controller[]
// }

  export class Controller { // room controller
    id: number;
    driverPrefix: string;
    designation: string;
    locationId: number;
    restoreDefaultOnCheckout: boolean;
    isOnline: boolean;
    projectSubObjectId: number;
    created: Date;
    updated: Date;
    accessCards: any[];
    projectSubObject?: any;
    controllerCommissioningParameters: ControllerCommisioningParams;
    controllerProperties: ControllerProperties;
    controllerSettings: ControllerSettings;
    location?: any;
    localCommandCooling: boolean;
    localCommandPanelBlocked: boolean;
    isPolling: boolean;
    name: string;
    address: number;
    object: string;
    subObject: string;
    zone: string;
    subZone: string;
    ipAddress: string;
    ipPort: number;
    update?: boolean;


  constructor() {}

  public static isAccessControl(controller: Controller) {
    return controller.controllerSettings.$values.some((setting: Setting)=> {
      return setting.name.toLowerCase() === 'mcutype' && setting.value.toLowerCase() === 'accesscontrol_v1'
    })
  }

  public static isGuestRoom(controller: Controller) {
    return controller.controllerSettings.$values.some((setting: Setting)=> {
      return setting.name.toLowerCase() === 'mcutype' && setting.value.toLowerCase() === 'room2'
    })
  }

  public static isIOCommonArea(controller: Controller) {
    return controller.controllerSettings.$values.some((setting: Setting)=> {
      return setting.name.toLowerCase() === 'mcutype' && setting.value.toLowerCase() === 'iocommonarea'
    })
  }

  public static isHvacCommonArea(controller: Controller) {
    return controller.controllerSettings.$values.some((setting: Setting)=> {
      return setting.name.toLowerCase() === 'mcutype' && setting.value.toLowerCase() === 'hvaccommonarea'
    })
  }

  public static roomCanBeJoined(controller: Controller) {
    return controller.controllerSettings.$values.some((setting: Setting)=> {
      return setting.name.toLowerCase() === 'joinedrooms';
    })
  }

  public static getCardType(controller: Controller) {
    const targetSetting = controller.controllerSettings.$values.find( (setting: Setting) => setting.name.toLowerCase() === 'cardtype');
    return targetSetting.value;
  }

  public static getMaxGuestCardsNumber(controller: Controller) {
    const targetSetting = controller.controllerSettings.$values.find( (setting: Setting) => setting.name.toLowerCase() === 'guestcardpositions');
    return +targetSetting.value;
  }

  public static getMaxStaffCardsNumber(controller: Controller) {
    const guestsPositions = this.getMaxGuestCardsNumber(controller);
    const targetSetting = controller.controllerSettings.$values.find( (setting: Setting) => setting.name.toLowerCase() === 'cardpositions');
    return +targetSetting.value - guestsPositions;
  }

  public static getMainController(controllers: Controller []) {
    const sortedControllers = controllers.slice();
    sortedControllers.sort((controllerA: Controller, controllerB: Controller)=> { // sorting by subzone. low to high
      return Number(controllerA.subZone) - Number(controllerB.subZone)
    });
    const mainController = sortedControllers[0];
    return mainController
  }


  public static isPropertyInControllers(controllers: Controller [],propCodeFrom: number,propCodeTo: number ): boolean {
    return controllers.some((cont)=> {
      return cont.controllerProperties.$values.some((prop)=> {
        return prop.type >= propCodeFrom && prop.type <= propCodeTo
      })
    })
  }

  public static getControllerByLocationId(controllers: Controller[], locationId: string) {
    return controllers.find((cont)=> {
      return cont.designation === locationId;
    })
  }

}
