import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';

@Pipe({
  name: 'controllerNotPolled'
})
export class ControllerNotPolledPipe implements PipeTransform {

  transform(controller: Controller, notPolledControllersList: any[]): boolean {
    return notPolledControllersList.some((cl)=> {
      return cl.object === controller.object && cl.subObject === controller.subObject
      && cl.zone === controller.zone && cl.subZone === controller.subZone
    });
  }

}
