import { Component, OnInit, OnDestroy, HostListener, Optional, Inject, LOCALE_ID, ElementRef, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { User } from '../../../core/models/user/user.model';
import { CardReaderService } from './../../../core/services/card-reader.service';
import { enterAnimation } from '../../animations/animations';
import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';
import { AlarmAlert } from '../../../core/models/alarms/alarm-alert.model';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { ApiAlarmsService } from 'src/app/modules/alarms/services/http/api-alarms.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { Platform } from '@ionic/angular';
import { UserSettingsService } from '../../services/user-settings.service';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { FiltersService } from '../../services/filters.service';
import { ApiHvacModesService } from 'src/app/modules/groups/services/api-hvac-modes.service';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { HvacModesService } from 'src/app/modules/groups/services/hvac-modes.service';
import { Router } from '@angular/router';
import { HvacModeType } from 'src/app/core/models/hvac-mode-type.enum';
import { MqttService } from 'ngx-mqtt';
import { Alarms } from 'src/app/core/models/alarms.model';
import { ApiProjectService, LicenceData } from 'src/app/modules/project/services/http/api-project.service';
import { MqttMessageQueueService } from 'src/app/core/app-load/mqtt-message-queue.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  animations: [enterAnimation],
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('audioOption') audioPlayerRef: ElementRef;
  mqttQueueLength$: Observable<number> = this.mqttMessageQueueService.getQueueLength();
  soundAvailable: boolean = true;
  user: User;
  userSubscription: Subscription;
  swPermissions = SoftwarePermissionId;
  cardReaderConnected: boolean;
  cardReaderConnectedSubscription: Subscription;
  dropdownOpened = false;
  langDropdownOpened = false;
  alarms: AlarmAlert[] = [];
  alarmsSubscription: Subscription;
  alarmsInit: boolean;
  currentLang: string;
  objectDropdownOpened = false;
  totalUnconfirmedAlarms: Observable<number> = this.alarmAlertsService.getUnconfirmedAlarmsNumber()

  objects$ = this.projectService.getObjects();
  objectsFilter$: Observable<number[]> = this.filterService.getObjectsFilter(); // location ids
  objFilterSub: Subscription;
  HvacModeType = HvacModeType;
  activeHvacModeGlobal$: Observable<HvacModeType> = this.hvacModesService.getActiveHvacModeGlobal()
  mqttDown = false;

  queryParams = {
    pageNumber: 1,
    pageSize: 50
  };
  requestBody: Alarms ={  Filtering: '',
                          Sorting: '',
                          DateTimeFrom: '',
                          DateTimeTo: '',
                          Levels: [],
                          Types: [],
                          Confirmed: [0],
                          ConfirmUsers: [],
                          SortingLevelTimeDsc: true
                        }

  projectReadySubscription: Subscription;
  projectReady$ = this.apiProjectService.isProjectReady();
  cardSyncActiveSubscription: Subscription;
  cardSyncActive = false;
  User= User;
  licenceData: LicenceData;
  showExpireMessage = false;

  constructor(private authService: AuthenticationService,
              private hvacModesService: HvacModesService,
              private api: AuthenticationService,
              private cardReaderService: CardReaderService,
              private apiAlarmService: ApiAlarmsService,
              private projectService: ProjectService,
              public platform: Platform,
              private userSettingsService: UserSettingsService,
              private filterService: FiltersService,
              private apiHvacModesService: ApiHvacModesService,
              private currentUserStoreService: CurrentUserStoreService,
              private router: Router,
              private mqttService: MqttService,
              private apiProjectService: ApiProjectService,
              private mqttMessageQueueService: MqttMessageQueueService,
              @Inject(LOCALE_ID) private localeId: string,
              @Optional() private alarmAlertsService?: AlarmAlertsService

             ) { }

  ngOnInit() {
    this.getLicence();
    this.getIsCardSyncActive();
    this.currentLang = this.userSettingsService.getLanguage();
    this.user = Object.assign(new User(), this.currentUserStoreService.getUser());
    if (this.user === undefined) {
      if (window.location.href.includes('home')) {
        this.api.getUserByToken().subscribe();
      }
    }
    this.userSubscription = this.currentUserStoreService.userChanged.subscribe(() => {
      this.user = Object.assign(new User(), this.currentUserStoreService.getUser());
      if (this.userHasPermissionToReceiveAnyAlarmType(this.user) && !this.alarmsInit) {
        this.getAlarms();
        this.alarmsInit = true;
      }
    });
    if (this.userHasPermissionToReceiveAnyAlarmType(this.user) && !this.alarmsInit) {
      this.getAlarms();
      this.alarmsInit = true;
    }
    this.apiAlarmService.getUnconfirmedAlarms(this.requestBody, this.queryParams).subscribe();
    this.getCardReaderConnectedStatus();
    this.selectFirstObjectDefault();
    this.objFilterSub = this.objectsFilter$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
     ).subscribe((objsFilter: number[])=> {
      if (objsFilter === null || objsFilter.length === 0 ) {
        this.apiHvacModesService.getActiveHvacMode('0').subscribe()
      } else {
        this.apiHvacModesService.getActiveHvacMode(objsFilter[0].toString()).subscribe()
      }
    })

    this.mqttService.onConnect.subscribe( value => {
      this.mqttDown = false;
    })

    this.mqttService.onEnd.subscribe( value => {
      this.mqttDown = true;
    })

    this.mqttService.onClose.subscribe( () => {
      this.mqttDown = true;
    })

    // this.playAlarmSound();

  }

  onClickHvacMode() {
    if (this.user.havePermission(SoftwarePermissionId.LocationGroupsActivatePresets)) {
      this.router.navigate(['/hvac-mode'])
    }
  }


  userHasPermissionToReceiveAnyAlarmType(user: User) {
    return user.havePermission(SoftwarePermissionId.AlarmsSosReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsRoomBurglaryReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsRoomSafeBurglaryReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsTemperatureDifferenceReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsWaterFloodReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsPowerOutageReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsDCOutputsFailureReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsControllerTemperatureReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsWindowOpenTooLongReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsDoorOpenTooLongReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsRoomSafeCommErrorReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsCommunicationErrorReceive);
    /* return user.havePermission(SoftwarePermissionId.AlarmsBurglaryReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsHvacReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsOpenTooLongReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsSafetyReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsSosReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsSystemReceive); */
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    const el = event.target as HTMLElement;
    if (!el.classList.contains('header-title') && !el.classList.contains('header-arrow')
      && !el.classList.contains('header-dropdown') && !el.classList.contains('header-user')) {
      this.dropdownOpened = false;
      this.langDropdownOpened = false;
      this.objectDropdownOpened = false;
    }
  }

  selectFirstObjectDefault() {
    this.projectReadySubscription = this.apiProjectService.projectReady$.subscribe( value => {
      if (value) {
        this.objectsFilter$.subscribe( value => {
          if(value === null || value.length === 0) {
            this.objects$.subscribe(objects => {
              this.filterService.setObjectsFilter([objects[0].projectObjectId], false);
              this.filterService.setCommonAreaObjectsFilter([objects[0].projectObjectId], false);
            })
          }
        })
      }
    })
  }


  getAlarms() {
    this.alarms = this.alarmAlertsService.getAlarms();
    this.alarmsSubscription = this.alarmAlertsService.alarmsChanged.subscribe(alarms => {
      this.alarms = this.alarmAlertsService.getAlarms()
    });
  }

  playAlarmSound() {
    let audio = new Audio()
    audio.src='../../../../assets/sounds/beep-beep.mp3';
    //we play a sound is some alarm is danger lvl
    setInterval( () => {
      if (this.alarms.some( alarm => (alarm.level === 3 && alarm.confirmed == false))) {
          var playedPromise = audio.play();
          this.soundAvailable = true;
          if (playedPromise) {
              playedPromise.catch((e) => {
                   if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                         this.soundAvailable = false;
                    }
               })
           } else {
            audio.play()
           }
      }
    }, 5000)


  }



  get unconfirmedAlerts() {
    return this.alarms.reduce((r, a)=> {
      return r + +(!a.confirmed);
    }, 0);
  }


  getCardReaderConnectedStatus() {
    this.cardReaderConnected = this.cardReaderService.getCardReaderConnected();
    this.cardReaderConnectedSubscription = this.cardReaderService.cardReaderConnectedChanged.subscribe(() =>
        this.cardReaderConnected = this.cardReaderService.getCardReaderConnected());
  }

  setLang(lang: string) {
    if (lang === 'en') {
      this.userSettingsService.setLanguage('en');
    } else if (lang === 'hr') {
      this.userSettingsService.setLanguage('hr');
    }
    location.reload()
  }

  logout() {
    this.authService.logout();
  }


  selectObject(object: ProjectObject) {
    let refreshRoomsPage = false;
    let refreshCommonaAreaPage = false;
    if (this.router.url === '/rooms') {
      refreshRoomsPage = true;
    }
    if (this.router.url === '/common-area') {
      refreshCommonaAreaPage = true;
    }
    if (object) { // select object
      this.filterService.setObjectsFilter([object.projectObjectId], refreshRoomsPage);
      this.filterService.setCommonAreaObjectsFilter([object.projectObjectId], refreshCommonaAreaPage);
    } else { // select all objects  []
      this.filterService.setObjectsFilter([], refreshRoomsPage)
      this.filterService.setCommonAreaObjectsFilter([], refreshCommonaAreaPage);
    }

  }

  getIsCardSyncActive(){
    this.cardSyncActiveSubscription = this.projectService.cardSyncActive$.subscribe(value => {
      this.cardSyncActive = value;
    });
  }

  getLicence() {
    this.apiProjectService.getLicence().subscribe(value => {
      this.licenceData = value;
      const expiryDate = new Date(this.licenceData.validTo);
      const today = new Date();
      const diffTime = expiryDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 15){
        this.showExpireMessage = true;
      }else{
        this.showExpireMessage = false;
      }
    });

  }

  ngOnDestroy() {
    if (this.cardReaderConnectedSubscription) {
      this.cardReaderConnectedSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.alarmsSubscription) {
      this.alarmsSubscription.unsubscribe();
    }
    if (this.objFilterSub) {
      this.objFilterSub.unsubscribe();
    }
    if (this.projectReadySubscription) {
      this.projectReadySubscription.unsubscribe();
    }
    if (this.cardSyncActiveSubscription) {
      this.cardSyncActiveSubscription.unsubscribe();
    }
  }
}
