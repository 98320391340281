import { Pipe, PipeTransform } from '@angular/core';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getRoomStatusColor'
})
export class GetRoomStatusColorPipe implements PipeTransform {

  transform(location: Location, cardTypes: CardType[], updated: Date): string {
    const controllers = location.controllers.$values;
    //TODO PROVJERI OVO SVE
    if (cardTypes.length !== 0) {
      let sosIsActive = false;
      let cardIsDeposited = false;
      let roomIsRented = false;
      let anyBurglaryActive = false;
      let cardTypeProp: Property;
      const guestCode = cardTypes.find((type: CardType)=> {
        return type.name.toLowerCase() === 'guest';
          }).code;

      for (const controller of controllers) {
        controller.controllerProperties.$values.forEach((prop: Property) => {
          if (Property.isSOS(prop) && sosIsActive === false && Property.isActive(prop)) {
            sosIsActive = true
          }
          else if (Property.isCardDeposited(prop) && cardIsDeposited === false && Property.isActive(prop)) {
            cardIsDeposited = true
          }
          else if (Property.isAnyBurglaryProperty(prop) && anyBurglaryActive === false && Property.isActive(prop)) {
            anyBurglaryActive = true;
          }
          else if(Property.isCardType(prop)) {
            cardTypeProp = prop;
          }
        });

        if (location.isRented && roomIsRented === false) {
          roomIsRented = true;
        }
      }

      let isStaff = false;

      if (cardTypeProp?.value !== undefined && cardTypeProp?.value!== null) {
          const cardType = cardTypes.find((ct: CardType)=> {
            return cardTypeProp.value.toString() === ct.code.toString()
          });
          isStaff = CardType.isStaffCard(cardType)

      }
      if (anyBurglaryActive) {
        return 'unauthorized-access'
      } else if (cardIsDeposited && sosIsActive && (Number(cardTypeProp?.value) === Number(guestCode)) ) {
        return 'sos-guest-in-room'
      } else if (!cardIsDeposited && sosIsActive) {
        return 'sos-card-not-deposited'
      } else if (cardIsDeposited && (Number(cardTypeProp?.value) === Number(guestCode))) {
        return 'guest-card-deposited'
      } else if (cardIsDeposited && isStaff ) {
        return 'staff-card-deposited'
      } else if (roomIsRented) {
        return 'room-rented';
      } else {
        return 'room-not-rented';
      }
    } else {
      return 'room-not-rented';
    }

  }

}
