import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { User } from 'src/app/core/models/user/user.model';
import { DefaultsPreset, FrontendSettings } from 'src/app/modules/settings/services/http/api-settings.service';
import { LocationGroup } from './api-location-groups.service';
import { Observable, map, tap } from 'rxjs';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';
import { Location } from 'src/app/core/models/project/location.model';
import { AlarmType } from 'src/app/core/models/alarms/alarm-type.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { LocationGroupsService } from './location-groups.service';
import { AlarmSubscriptionsService } from 'src/app/modules/alarms/services/alarm-subscriptions.service';
import { ApiProjectService, KeyOption } from 'src/app/modules/project/services/http/api-project.service';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { Card } from 'src/app/core/models/card/card.model';
import { GuestCard } from 'src/app/modules/users/services/http/api-users.service';
import { PaginationData } from 'src/app/core/models/pagination-data.model';
import { HowToArticle } from 'src/app/modules/how-to/services/api-how-to.service';
import { AutomationService } from 'src/app/modules/automation/services/automation.service';
import { Automation } from 'src/app/core/models/automation/automation.model';
import { AccessLog } from 'src/app/core/models/logs/access-log.model';
import { AlarmLog } from 'src/app/core/models/logs/alarm-log.model';
import { ValueGraph } from 'src/app/core/models/logs/value-graph.model';
import { HvacMode } from 'src/app/core/models/hvac-modes/hvac-mode.model';
import { HvacModesService } from 'src/app/modules/groups/services/hvac-modes.service';
import { Property } from 'src/app/core/models/project/property.model';
import { LocationPresetCrossRef } from 'src/app/core/models/cross-ref.model';
import { UsersService } from 'src/app/modules/users/services/users.service';
import { UserType } from 'src/app/core/models/user/user-type.model';
import { AuditLog } from 'src/app/core/models/logs/audit-log.model';
import { CardEventLog } from 'src/app/core/models/logs/card-event-log.model';
import { Role } from 'src/app/core/models/role.model';
import { RolesService } from 'src/app/modules/roles/services/roles.service';
import { Permission } from 'src/app/core/models/permissions/permission.model';

export interface DemoModeData {
    RoomsFat: Location[];
    CommonAreaFat: Location[]
    LocationsFat: Location[];
    User: User;
    FrontendSettings: FrontendSettings;
    CardTypes: CardType[];
    Objects: ProjectObject[],
    Subobjects: ProjectSubObject[],
    Groups:LocationGroup [];
    Floors:LocationGroup [];
    Presets: DefaultsPreset[];
    UnconfirmedAlarms: AlarmAlert[];
    AlarmTypes: AlarmType[];
    StaffCards: Card[];
    GuestCards: GuestCard[];
    Pagination: PaginationData;
    GuestCardsByLocation: Card[],
    HowToArticle: HowToArticle[],
    KeyOption: KeyOption[];
    Automations: Automation[],
    AccessLog: AccessLog[],
    AlarmLog: AlarmLog[];
    AuditLog: AuditLog[];
    CardEventLog: CardEventLog[]
    ValueLog: ValueGraph[];
    TemperatureLog: ValueGraph[];
    TableProperties: string[];
    HvacModes: HvacMode[];
    PresetProps: Property[];
    CrossReff: LocationPresetCrossRef[],
    Staff: User[]
    UserTypes: UserType[],
    CardEventGraphData: any,
    Roles: Role[],
    Permissions: Permission[];
  }

@Injectable({
  providedIn: 'root'
})
export class DemoModeService {

        private readonly DEMO_DATA = 'assets/demo-mode.json';
        constructor(private http: HttpClient,
                    private settingsService: SettingsService,
                    private cardsService: CardsService,
                    private projectService: ProjectService,
                    private locationGroupsService: LocationGroupsService,
                    private alarmSubscriptionsService: AlarmSubscriptionsService,
                    private apiProjectService: ApiProjectService,
                    private currentUserStoreService: CurrentUserStoreService,
                    private automationService: AutomationService,
                    private hvacModesService: HvacModesService,
                    private usersService: UsersService,
                    private rolesService: RolesService) { }


        getData(): Observable<DemoModeData> {
            return this.http.get<DemoModeData>(this.DEMO_DATA);
          }

        setupDemoProject() {
           this.getData().subscribe( (value:DemoModeData) => {
               this.currentUserStoreService.setUser(value.User)
                this.settingsService.setFrontendSettings(value.FrontendSettings);
                this.settingsService.setupViewConfigs();
                this.settingsService.initializeViewFromStorage(value.User);
                this.settingsService.initializeGuestStayShortcut(value.User);
                this.cardsService.setCardTypes(value.CardTypes);
                this.projectService.setObjects(value.Objects);
                this.projectService.setSubObjects(value.Subobjects);
                this.locationGroupsService.setFloorList(value.Floors);
                this.alarmSubscriptionsService.setAlarmTypes(value.AlarmTypes);
                this.projectService.setAllLocations(value.LocationsFat, true)
                this.apiProjectService.projectReady$.next(true);
                this.projectService.setLoadingFilteredLocations(false)
                this.locationGroupsService.setLocationGroups(value.Groups);
                this.automationService.setAutomations(value.Automations);
                this.hvacModesService.setHvacModes(value.HvacModes);
                this.settingsService.setDefaultPresets(value.Presets);
                this.usersService.setUserTypes(value.UserTypes);
                this.usersService.setUsers(value.Staff);
                this.usersService.setNonGuestUsers(value.Staff)
                this.rolesService.setRoles(value.Roles);
                this.rolesService.setPermissions(value.Permissions);
            })
        }

        setRoomsLocations() {
          this.getData().subscribe( (value:DemoModeData) => {
            this.projectService.setFilteredLocations(value.RoomsFat)
            this.projectService.setLoadingFilteredLocations(false)
            this.apiProjectService.filteredFatLocationsIsReady$.next(true);
          })
        }

        setCommonAreaLocations() {
          this.getData().subscribe( (value:DemoModeData) => {
            this.projectService.setFilteredLocations(value.CommonAreaFat)
            this.projectService.setLoadingFilteredLocations(false)
            this.apiProjectService.filteredFatLocationsIsReady$.next(true);
          })
        }

}
