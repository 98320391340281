import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { StoredFilter } from 'src/app/core/models/project/stored-filter.model';
import { ITemperatureDifferenceFilter, ITemperatureFilter } from 'src/app/core/models/view-filter.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { LocationGroup } from './api-location-groups.service';
import { LocationTagsFilter, LocationsFilter } from 'src/app/core/models/locations-filter.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';

@Injectable({
  providedIn: 'root'
})

export class FiltersService {

  private searchFilter$ = new BehaviorSubject<string>('');
  private filters$ = new BehaviorSubject<LocationsFilter>(new LocationsFilter([1]));
  private commonAreaFilters$ = new BehaviorSubject<LocationsFilter>(new LocationsFilter([2,3,4]));
  loadedFiltersForUserId: number = undefined;
  loadedCommonAreaFiltersForUserId: number = undefined;

  constructor(
    private currentUserStoreService: CurrentUserStoreService,
    private apiProjectService: ApiProjectService,
    private projectService: ProjectService) {}

  getFilters() {
    return this.filters$.asObservable();
  }

  setFilters(controllerFilters: LocationsFilter, refresh = true) {
    const activeUserId = this.currentUserStoreService.getUser().userId;
    const newControllerFilters = structuredClone(controllerFilters);
    StoredFilter.storeGuestRoomFilters(activeUserId, newControllerFilters)
    this.filters$.next(newControllerFilters);
    if (refresh === true) {
      this.projectService.setLoadingFilteredLocations(true)
      this.apiProjectService.getFilteredLocationsFat(newControllerFilters).subscribe(()=> {
        this.projectService.setLoadingFilteredLocations(false)
      });
    }
  }


  getCommonAreaFilters() {
    return this.commonAreaFilters$.asObservable();
  }

  setCommonAreaFilters(controllerFilters: LocationsFilter, refresh = true) {
    const activeUserId = this.currentUserStoreService.getUser().userId;
    const newControllerFilters = structuredClone(controllerFilters);
    StoredFilter.storeCommonAreaFilters(activeUserId, newControllerFilters)
    this.commonAreaFilters$.next(newControllerFilters);
    if (refresh === true) {
      this.projectService.setLoadingFilteredLocations(true)
      this.apiProjectService.getFilteredLocationsFat(newControllerFilters).subscribe(()=> {
        this.projectService.setLoadingFilteredLocations(false)
      });
    }

  }

  initFilters(controllerFilters: LocationsFilter) {
    const activeUserId = this.currentUserStoreService.getUser().userId;
    const newControllerFilters = structuredClone(controllerFilters);
    StoredFilter.storeGuestRoomFilters(activeUserId, newControllerFilters)
    this.filters$.next(newControllerFilters);
  }

  initCommonAreaFilters(controllerFilters: LocationsFilter) {
    const activeUserId = this.currentUserStoreService.getUser().userId;
    const newControllerFilters = structuredClone(controllerFilters);
    StoredFilter.storeCommonAreaFilters(activeUserId, newControllerFilters)
    this.commonAreaFilters$.next(newControllerFilters);
  }

  getSearchFilter() {
    return this.searchFilter$.asObservable();
  }

  setSearchFilter(newSearchFilter: string) {
    this.searchFilter$.next(newSearchFilter);
  }

  getObjectsFilter(): Observable<number[]> { // return object ids
    return this.filters$.pipe(map((f: LocationsFilter)=> {
      return f?.objects
    }))
  }

  getObjectsFilterFull(): Observable<ProjectObject[]> { // return full objects
    return this.projectService.getObjects().pipe(switchMap((objects: ProjectObject[])=> {
      return this.filters$.pipe(map((f: LocationsFilter)=> {
        return f?.objects?.map((objId)=> {
          return objects.find((obj: ProjectObject)=> {
            return obj.projectObjectId === objId;
          })
        })
      }))
    }))
  }

  setObjectsFilter(objectLocationIds: number[], refresh = true) {
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.objects = objectLocationIds
    newFilters.floors = [];
    this.setFilters(newFilters, refresh)
  }

  setCommonAreaObjectsFilter(objectLocationIds: number[], refresh = true) {
    // const newFilters =  JSON.parse(JSON.stringify(this.commonAreaFilters$.getValue()));
    const newFilters = structuredClone(this.commonAreaFilters$.getValue());

    newFilters.objects = objectLocationIds
    newFilters.floors = [];
    this.setCommonAreaFilters(newFilters, refresh)
  }

  getHvacSpeed(): Observable<null | 1 | 2 | 3> {
    return this.filters$.pipe(map((f: LocationsFilter)=> {
      return f.customControllerFilter.Hvac.HvacSpeed
    }))
  }


  // FLOOR FILTER

  getFloorsFilter(): Observable<number[]> {
    return this.filters$.pipe(map((f: LocationsFilter)=> {
      return f.floors
    }))
  }

  getTagsFilter(corridors: boolean): Observable<string[]> {
    if (corridors) {
      return this.commonAreaFilters$.pipe(map((f: LocationsFilter)=> {
        return f.locationTags?.locationTags
      }))
    } else {
      return this.filters$.pipe(map((f: LocationsFilter)=> {
        return f.locationTags?.locationTags
      }))
    }
  }

  changeFloorFilter(floor: LocationGroup) {
    const newFilters = structuredClone(this.filters$.getValue());

    const floorLocIdIndex = newFilters.floors.findIndex((flId: number) => {
      return flId === floor.id
    })
    if (floorLocIdIndex === -1) {
      newFilters.floors.push(floor.id)
    } else {
      newFilters.floors.splice(floorLocIdIndex,1)
    }
    this.setFilters(newFilters)
  }


  changeTagsFilters(tagName, corridors: boolean) {
    let newFilters
    let tagIndex;
    if (corridors) {
      newFilters = structuredClone(this.commonAreaFilters$.getValue());
    } else {
      newFilters = structuredClone(this.filters$.getValue());
    }
    if (newFilters.locationTags != null && newFilters.locationTags.locationTags != null) {
      tagIndex = newFilters.locationTags.locationTags.findIndex( tag => {
        return tag === tagName
      })
    } else {
      newFilters.locationTags = new LocationTagsFilter()
      newFilters.locationTags.locationTags = []
      newFilters.locationTags.locationTags.push(tagName)
    }

    if (tagIndex === -1) {
      newFilters.locationTags.locationTags.push(tagName)
    } else {
      newFilters.locationTags.locationTags.splice(tagIndex, 1)
    }
    if (corridors) {
      this.setCommonAreaFilters(newFilters)
    } else {
      this.setFilters(newFilters)
    }
  }

  selectAllFloors(refresh = true) {
    const newFilters = structuredClone(this.filters$.getValue());
    newFilters.floors = [];
    this.setFilters(newFilters, refresh)
  }

  clearTagsFilter(corridors: boolean) {
    let newFilters
    if (corridors) {
      newFilters = structuredClone(this.commonAreaFilters$.getValue());
    } else {
      newFilters = structuredClone(this.filters$.getValue());
    }
    newFilters.locationTags = new LocationTagsFilter()
    newFilters.locationTags.locationTags = []
    if (corridors) {
      this.setCommonAreaFilters(newFilters)
    } else {
      this.setFilters(newFilters)
    }
  }


  // COMMON AREA FLOOR FILTER
  getCommonAreaFloorsFilter(): Observable<number[]> {
    return this.commonAreaFilters$.pipe(map((f: LocationsFilter)=> {
      return f.floors
    }))
  }

  changeCommonAreaFloorFilter(floor: LocationGroup) {
    const newFilters = structuredClone(this.commonAreaFilters$.getValue());

    const floorLocIdIndex = newFilters.floors.findIndex((flId: number) => {
      return flId === floor.id
    })
    if (floorLocIdIndex === -1) {
      newFilters.floors.push(floor.id)
    } else {
      newFilters.floors.splice(floorLocIdIndex,1)
    }
    this.setCommonAreaFilters(newFilters)
  }

  selectAllCommonAreaFloors(refresh = true) {
    const newFilters = structuredClone(this.commonAreaFilters$.getValue());
    newFilters.floors = [];
    this.setCommonAreaFilters(newFilters, refresh)
  }


  // GROUP FILTER

  getGroupsFilter(): Observable<number[]> {
    return this.filters$.pipe(map((f: LocationsFilter)=> {
      return f.groups
    }))
  }

  changeGroupFilter(group: LocationGroup) {
    const newFilters = structuredClone(this.filters$.getValue());
    let groupLocIdIndex;
    if (newFilters.groups != null) {
      groupLocIdIndex =newFilters.groups.findIndex((grId: number) => {
        return grId === group.id
      })
    } else {
      newFilters.groups = []
      newFilters.groups.push(group.id)
    }
    if (groupLocIdIndex === -1) {
      newFilters.groups.push(group.id)
    } else {
      newFilters.groups.splice(groupLocIdIndex,1)
    }
    this.setFilters(newFilters)
  }

  selectAllGroups() {
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.groups = [];
    this.setFilters(newFilters)
  }

  groupingFilterIsActive(): Observable<boolean> {
    return this.filters$.pipe(map((filters)=> {
      return filters?.locationTags?.locationTags?.length >0 || filters?.floors?.length>0 || filters?.groups?.length>0;
    }))
  }

  statusFilterIsActive(): Observable<boolean> {
    return this.filters$.pipe(map((filters)=> {
      const cleaningFilterActive = Object.values(filters.customControllerFilter.Cleaning)?.some((f)=> {
        return f !== null;
      })
      if (cleaningFilterActive) {
        return true;
      }
      const hvacFilterActive = Object.values(filters.customControllerFilter.Hvac).some((f)=> {
        return f !== null;
      })
      if (hvacFilterActive) {
        return true;
      }
      const statusFilterActive = Object.values(filters.customControllerFilter.Statuses)?.some((f)=> {
        return f !== null;
      })
      if (statusFilterActive) {
        return true;
      }
      return false
    }))
  }



  commonAreaFloorFilterIsActive(): Observable<boolean> {
    return this.commonAreaFilters$.pipe(map((filters)=> {
      return filters?.floors?.length > 0;
    }))
  }

  commonAreaTagsFilterIsActive(): Observable<boolean> {
    return this.commonAreaFilters$.pipe(map((filters)=> {
      return filters?.locationTags?.locationTags?.length > 0;
    }))
  }

  locationTypeFilterIsActive(): Observable<boolean> {
    return this.commonAreaFilters$.pipe(map((filters)=> {
      return filters?.locationTypes.length < 3; // there are max 3 location types in common areas filter
    }))
  }



  // HVAC FILTER


   getTempType() {
    return this.filters$.pipe(map((filters)=> {
      return filters.customControllerFilter.Hvac?.TemperatureFilter?.Type;
     }));
   }

   getComparisonOption() {
    return this.filters$.pipe(map((filters)=> {
      return filters.customControllerFilter.Hvac?.TemperatureFilter?.Comparison;
     }));
   }

   setOperatorOption(operatorOption: 0 | 1 | 2) {
      const newFilters = structuredClone(this.filters$.getValue());

      newFilters.customControllerFilter.Hvac.TemperatureFilter.Comparison = operatorOption;
      this.setFilters(newFilters);
   }

   getLocationOption() {
    return this.filters$.pipe(map((filters)=> {
      return filters.customControllerFilter.Hvac?.TemperatureFilter?.Location
     }));
   }

   getTempFilter() {
    return this.filters$.pipe(map((filters)=> {
      return filters.customControllerFilter.Hvac?.TemperatureFilter
     }));
   }

   getTemperature() {
    return this.filters$.pipe(map((filters)=> {
     return filters.customControllerFilter.Hvac?.TemperatureFilter?.Value;
    }));
  }

  setTempFilter(newValue: null | ITemperatureFilter) {
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.customControllerFilter.Hvac.TemperatureFilter = newValue;
    this.setFilters(newFilters);
  }

  setTempDiffFilter(newValue: null | ITemperatureDifferenceFilter) {
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.customControllerFilter.Hvac.TemperatureDifferenceFilter = newValue;
    this.setFilters(newFilters);
  }

   setTemperature(temperature: any) {
    const newFilters = structuredClone(this.filters$.getValue());

    if (temperature === undefined || temperature === null || temperature === '') {
      newFilters.customControllerFilter.Hvac.TemperatureFilter.Value = null;
    } else {
      newFilters.customControllerFilter.Hvac.TemperatureFilter.Value = temperature;
    }
    this.setFilters(newFilters);
   }

  changeTempType() {
    const currentTempOptionValue = this.filters$.getValue().customControllerFilter.Hvac.TemperatureFilter.Type
    if (currentTempOptionValue === 1) {
      this.setTempType(0);
    } else {
      this.setTempType(1);
    }
   }

  setTempType(tempType: 0 | 1) {
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.customControllerFilter.Hvac.TemperatureFilter.Type = tempType;
    this.setFilters(newFilters)
   }

  changeOperatorOption() {
    const currentNumericalOperatorValue = this.filters$.getValue().customControllerFilter.Hvac.TemperatureFilter.Comparison;
    if (currentNumericalOperatorValue === 0) {
      this.setOperatorOption(1);
    } else if (currentNumericalOperatorValue === 1) {
      this.setOperatorOption(2);
    } else {
      this.setOperatorOption(0);
    }
  }

  changeRoomOrBathroom() {
    const currentLocationOption = this.filters$.getValue().customControllerFilter.Hvac.TemperatureFilter.Location
    if (currentLocationOption === 1) {
      this.setTempLocation(0);
    } else {
      this.setTempLocation(1);
    }
  }

  setTempLocation(newValue: 0 | 1) {
   const newFilters = structuredClone(this.filters$.getValue());
   newFilters.customControllerFilter.Hvac.TemperatureFilter.Location = newValue;
   this.setFilters(newFilters);
  }

  // Temperature difference filter

  getTempDiffLocationOption() {
    return this.filters$.pipe(map((filters)=> {
      return filters.customControllerFilter.Hvac.TemperatureDifferenceFilter?.Location;
     }));
   }

   getTempDiffComparisonOption() {
    return this.filters$.pipe(map((filters)=> {
      return filters.customControllerFilter.Hvac.TemperatureDifferenceFilter?.Comparison;
     }));
   }

   setTempDiffLocationOption(locationOption: 0 | 1) {
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.customControllerFilter.Hvac.TemperatureDifferenceFilter.Location = locationOption;
    this.setFilters(newFilters);
   }

   getTempDiffFilter() {
    return this.filters$.pipe(map((filters)=> {
     return filters.customControllerFilter.Hvac.TemperatureDifferenceFilter;
    }));
  }

   getTempDiffValue() {
     return this.filters$.pipe(map((filters)=> {
      return filters.customControllerFilter.Hvac.TemperatureDifferenceFilter?.Value;
     }));
   }

   setTempDiffValue(temperatureDiff: any) {
    const newFilters = structuredClone(this.filters$.getValue());

    if (temperatureDiff === undefined || temperatureDiff === null || temperatureDiff === '') {
      newFilters.customControllerFilter.Hvac.TemperatureDifferenceFilter.Value = null;
    } else {
      newFilters.customControllerFilter.Hvac.TemperatureDifferenceFilter.Value = temperatureDiff;
    }
    this.setFilters(newFilters);
   }


  changeTempDiffRoomOrBathroom() {
    const currentTempDiffLocationOption = this.filters$.getValue().customControllerFilter.Hvac.TemperatureDifferenceFilter.Location;
    if (currentTempDiffLocationOption === 1) {
      this.setTempDiffLocationOption(0);
    } else {
      this.setTempDiffLocationOption(1);
    }
  }

  changeTempDiffComparisonOption() {
    const currentNumericalOperatorValue = this.filters$.getValue().customControllerFilter.Hvac.TemperatureDifferenceFilter.Comparison;
    if (currentNumericalOperatorValue === 0) {
      this.setTempDiffOperatorOption(1);
    } else if (currentNumericalOperatorValue === 1) {
      this.setTempDiffOperatorOption(2);
    } else {
      this.setTempDiffOperatorOption(0);
    }
  }

  setTempDiffOperatorOption(operatorOption: 0 | 1 | 2) {
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.customControllerFilter.Hvac.TemperatureDifferenceFilter.Comparison = operatorOption;
    this.setFilters(newFilters);
 }

 clearStatusFilters() {
  const newFilters: LocationsFilter = this.filters$.getValue();
  newFilters.customControllerFilter.Cleaning = {
    Inspected:  null,
      Cleaned:  null,
      HighestCleaningStatus: null,
      MakeUpRoom:  null
  };
  newFilters.customControllerFilter.Hvac = {
    Active:  null,
    Heating:  null,
    Cooling:  null,
    HvacSpeed: null,
    FloorheatingRoom: null,
    FloorheatingBathroom: null,
    TemperatureFilter: null ,
    TemperatureDifferenceFilter: null
  };
  newFilters.customControllerFilter.Statuses = {
    Online: null,
    Rented: null,
    CardDeposited: null,
    AlarmActive: null,
    DoNotDisturb: null,
    RoomArmed: null,
    WindowOpen: null,
    DoorOpen: null,
    IgnoreWindow: null,
    IgnoreCardTray: null,
    MaidAtLocation: null,
    MiniBarOpen: null
    }

  this.setFilters(newFilters);
 }

  initializeFiltersFromStorage(userId: number) {
    if (this.loadedFiltersForUserId !== userId) { // check if filters are already loaded for this user
      const storedSettingsForUser = StoredFilter.getGuestRoomFilterFromStorage(userId);
      if (storedSettingsForUser) {
        this.initFilters(storedSettingsForUser.filters)
        this.loadedFiltersForUserId = userId;
      } else {
        this.initFilters(new LocationsFilter([1]));
      }
    }
  }

  initializeCommonAreaFiltersFromStorage(userId: number) {
    if (this.loadedCommonAreaFiltersForUserId !== userId) { // check if filters are already loaded for this user
      const storedSettingsForUser = StoredFilter.getCommonAreaFilterFromStorage(userId);
      if (storedSettingsForUser) {
        this.initCommonAreaFilters(storedSettingsForUser.filters)
        this.loadedFiltersForUserId = userId;
      } else {
        this.initCommonAreaFilters(new LocationsFilter([2,3,4]));
      }
    }
  }

  updateCleaningFilterStatus(statusName: string, newStatus: any | null ) {
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.customControllerFilter.Cleaning[statusName] = newStatus;
    this.setFilters(newFilters)
  }

  updateOtherFilterStatus(statusName: string, newStatus: boolean | null ) {
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.customControllerFilter.Statuses[statusName] = newStatus;
    this.setFilters(newFilters)
  }

  updateHvacFilterStatus(statusName: string, newStatus: boolean | null ) {
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.customControllerFilter.Hvac[statusName] = newStatus;
    this.setFilters(newFilters)
  }

  // COMMON AREA FILTER
  isControllerTypeFilterActive(controllerTypeFilter: 2 | 3 | 4) {
    const contTypesFilter = this.commonAreaFilters$.getValue().locationTypes;
    return contTypesFilter.includes(controllerTypeFilter);
  }

  onControllerTypeFilterClick(controllerTypeFilter: 2 | 3 | 4) {
    const newFilters = structuredClone(this.commonAreaFilters$.getValue());

    const typeIndex = newFilters.locationTypes.findIndex((type: number) => {
      return controllerTypeFilter === type
    })
    if (typeIndex === -1) {
      newFilters.locationTypes.push(controllerTypeFilter)
    } else {
      newFilters.locationTypes.splice(typeIndex,1)
    };

    this.setCommonAreaFilters(newFilters)
  }


  resetCommonAreaControllerTypeFilters() {
    const newFilters = structuredClone(this.commonAreaFilters$.getValue());

    newFilters.locationTypes = [2,3,4];
    this.setCommonAreaFilters(newFilters);
  }




}
